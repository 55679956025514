<template>
  <div class="container">
    <div class="row overflow-hidden">
      <!-- <div class="gif-container mx-auto">
        <img src="./adgif.gif" alt="GIF" />
        <a href="https://in.callawaygolf.com/" target="_blank">
          <img src="./banner.png" class="img-fluid rounded w-95" />
        </a>
        <a href="https://www.instagram.com/trinitygolf_india/" target="_blank">
          <img src="./tgcl.png" class="img-fluid rounded w-95" />
        </a>
      </div> -->
      <a
        href="https://www.instagram.com/chandigarhgolfleague"
        target="_blank"
        class="mb-1"
      >
        <!-- Placeholder -->
        <img src="./cglbanner.jpg" class="img-fluid mt-1 mb-1 rounded w-95" />
      </a>
    </div>
    <!-- <div class="row mb-3 mt-3">
      <div class="col text-center">
        <a
          href="https://www.youtube.com/watch?v=brdscO9NOg8"
          target="_blank"
          class="badge rounded-pill bg-danger align-middle text-decoration-none"
          >Catch the live action here!</a
        >
        <a
          href="https://www.youtube.com/live/9pcFEuMoaQI?si=xo7GhC0veRPBLblP"
          target="_blank"
          class="badge rounded-pill bg-danger align-middle text-decoration-none"
          >Catch the live action here!</a
        >
      </div>
    </div> -->
  </div>
</template>
<script setup></script>
<style scoped>
.gif-container {
  width: 100vw; /* 10% of viewport width */
  height: 15vh; /* 10% of viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
}
.gif-container img {
  /* max-width: 100%; */
  width: 85vw;
  max-height: 100%;
}
</style>
