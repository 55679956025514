<template>
  <span v-if="bids.id">
    {{ Object.keys(bids.bids).length }}
  </span>
</template>
<script setup>
import { defineProps, onMounted, onBeforeUnmount, ref } from "vue";
import { db } from "../../firebase/config";
import { collection, query, where, onSnapshot } from "firebase/firestore";
const props = defineProps(["subevent"]);
const bids = ref({ id: null });
var unsub_bids = () => {};
onBeforeUnmount(() => {
  unsub_bids();
});
onMounted(() => {
  const k = query(
    collection(db, "bids"),
    where("subevent", "==", props.subevent)
  );
  unsub_bids = onSnapshot(k, (querySnapshot) => {
    const cities = [];
    querySnapshot.forEach((docy) => {
      cities.push({ id: docy.id, ...docy.data() });
    });
    bids.value = cities[0];
  });
});
</script>
