<template>
  <!-- {{ store.masterdata.prizes }} -->
  <div class="row">
    <div class="col">
      Events selected:
      <button class="btn btn-sm btn-primary">
        {{ store.subeventsforprize.length }}
      </button>
    </div>
    <div class="col">
      <button
        class="btn btn-sm btn-primary"
        v-if="store.subeventsforprize.length"
        @click="generateresult()"
      >
        Generate
      </button>
      {{ results.length }}
      {{ users }}
      <i class="bi bi-plus-circle float-end" @click="toggleSpecialPrize()"></i>
    </div>
  </div>
  <div class="row mt-2 mb-2" v-if="special_prize">
    <div class="col">
      <input
        type="text"
        class="form-control"
        placeholder="Prize without selecting user"
        v-model.trim="special_narration"
      />
    </div>
    <div class="col">
      <button class="btn btn-sm btn-danger" @click="declareSpecial()">
        Declare Special
      </button>
    </div>
  </div>
  <div class="row mt-2" v-if="prize_string.u">
    <div class="col">{{ displayName(prize_string.u) }}</div>
    <div class="col">
      <input
        type="text"
        class="form-control"
        placeholder="Prize narration..."
        v-model.trim="prize_narration"
      />
    </div>
    <div class="col">
      <button
        class="btn btn-sm btn-primary"
        v-if="sortby && prize_narration"
        @click="declarePrize()"
      >
        Declare
      </button>
    </div>
  </div>

  <div class="row">
    <table class="table table-sm mt-2 border">
      <tr>
        <td>SNo</td>
        <td>Player</td>
        <td>Played</td>
        <td>
          Wins
          <i
            class="bi bi-caret-down-fill"
            @click="sortyBy('wins')"
            :class="getCaretClass('wins')"
          ></i>
        </td>
        <td>
          SR
          <i
            class="bi bi-caret-down-fill"
            @click="sortyBy('sr')"
            :class="getCaretClass('sr')"
          ></i>
        </td>
      </tr>
      <tr v-for="(b, index) in leaders" :key="b.u" :class="getrowbg(b.u)">
        <td @click="selectRow(b)" class="border rounded">{{ index + 1 }}</td>
        <td>{{ displayName(b.u) }}</td>
        <td>{{ b.p }}</td>
        <td>{{ b.w }}</td>
        <td>{{ b.s }}</td>
      </tr>
    </table>
  </div>
  <div class="row">
    <DeclaredPrizes></DeclaredPrizes>
  </div>
</template>
<script setup>
import { useStore } from "../../store/index";
import DeclaredPrizes from "./DeclaredPrizes.vue";
const store = useStore();
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  onSnapshot,
  addDoc,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../../firebase/config";
import { ref, watchEffect, computed, onMounted, onBeforeUnmount } from "vue";
const { customAlphabet } = require("nanoid");
const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const nanoid = customAlphabet(alphabet, 6);
const bids = ref([]);
const results = ref([]);
const leaders = ref([]);
const sortby = ref("");
const prize_string = ref({});
const prize_narration = ref("");
const special_prize = ref(false);
const special_narration = ref("");
const displaynames = ref({});
var unsubnames = () => {};
onMounted(() => {
  getDisplayNames();
});
onBeforeUnmount(() => {
  unsubnames();
});
const toggleSpecialPrize = () => {
  special_prize.value = !special_prize.value;
};
const declarePrize = async () => {
  const prizeObj = {
    user: prize_string.value.u,
    sno: 0,
    narration: prize_narration.value,
  };

  if (store.masterdata.prizes) {
    var max = 0;
    for (const property in store.masterdata.prizes) {
      if (store.masterdata.prizes[property].sno >= max) {
        max = store.masterdata.prizes[property].sno + 1;
      }
    }
    prizeObj.sno = max;
  }
  const frankDocRef = doc(db, "masterevents", store.masterdata.id);
  await updateDoc(frankDocRef, {
    ["prizes." + nanoid()]: prizeObj,
  });

  bids.value = [];
  results.value = [];
  leaders.value = [];
  prize_string.value = {};
  prize_narration.value = "";
};
const declareSpecial = async () => {
  const prizeObj = {
    user: "SpecialPrize",
    sno: 0,
    narration: special_narration.value,
  };

  if (store.masterdata.prizes) {
    var max = 0;
    for (const property in store.masterdata.prizes) {
      if (store.masterdata.prizes[property].sno >= max) {
        max = store.masterdata.prizes[property].sno + 1;
      }
    }
    prizeObj.sno = max;
  }
  const frankDocRef = doc(db, "masterevents", store.masterdata.id);
  await updateDoc(frankDocRef, {
    ["prizes." + nanoid()]: prizeObj,
  });

  bids.value = [];
  results.value = [];
  leaders.value = [];
  prize_string.value = {};
  prize_narration.value = "";
  special_narration.value = "";
  toggleSpecialPrize();
};
const getrowbg = (u) => {
  var str = "";
  if (prize_string.value.u == u) {
    str = "bg-warning";
  }
  return str;
};
const selectRow = (row) => {
  prize_string.value = { ...row };
  //prize_narration.value = "Daily winner";
};
const getCaretClass = (c) => {
  var str = "";
  if (sortby.value == c) {
    str = "text-danger";
  }
  return str;
};
const sortyBy = (str) => {
  sortby.value = str;
  var sorted_arr = [];
  const arr = [];
  if (sortby.value == "wins") {
    //const arr = [];
    leaders.value.forEach((s) => {
      arr.push(s);
    });
    sorted_arr = sortByField(arr, "w");
    leaders.value = sorted_arr;
  }
  if (sortby.value == "sr") {
    //const arr = [];
    leaders.value.forEach((s) => {
      arr.push(s);
    });
    sorted_arr = sortByField(arr, "s");
    leaders.value = sorted_arr;
  }
};

function sortByField(arr, field) {
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] < b[field]) {
      return 1;
    } else if (a[field] > b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
const generateresult = () => {
  bids.value = [];
  results.value = [];

  store.subeventsforprize.forEach(async (s) => {
    const q = query(collection(db, "bids"), where("subevent", "==", s));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docy) => {
      bids.value.push({ id: docy.id, ...docy.data() });
    });

    const docRef = doc(db, "subevents", s);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      //console.log("Document data:", docSnap.data());
      results.value.push({ id: docSnap.id, ...docSnap.data() });
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  });
  setTimeout(calculateLeaders, 5000);
};
const calculateLeaders = () => {
  leaders.value.forEach((l) => {
    //console.log(l.u);
    l.w = fetchWins(l.u);
    l.p = fetchPlayed(l.u);
    l.s = fetchSR(l.p, l.w);
  });
};
const users = computed(() => {
  var arr = [];

  bids.value.forEach((b) => {
    //console.log(b.bids);
    for (const property in b.bids) {
      var exists = false;
      leaders.value.forEach((l) => {
        if (l.u == property) {
          exists = true;
        }
      });
      if (!exists) {
        leaders.value.push({
          u: property,
          w: 0, //w = wins
          p: 0, //p = played
          s: 0, //s = strikerate
        });
      }
    }
  });
  // populateLeaders();
  return null;
});

watchEffect(() => {
  if (!store.subeventsforprize.length) {
    bids.value = [];
    results.value = [];
    leaders.value = [];
  }
});
const fetchWins = (u) => {
  var count = 0;
  bids.value.forEach((b) => {
    for (const property in b.bids) {
      if (property == u) {
        var u_choice = b.bids[property];
        // console.log(
        //   "user choice=>",
        //   b.subevent,
        //   u_choice.party,
        //   u_choice.bidid
        // );
        if (checkWin(b.subevent, u_choice.party, u_choice.bidid)) {
          count++;
        }
      }
    }
  });
  return count;
};
const fetchPlayed = (u) => {
  //return 7;
  var count = 0;
  bids.value.forEach((b) => {
    for (const property in b.bids) {
      if (property == u) {
        count++;
      }
    }
  });
  return count;
};
const fetchSR = (p, w) => {
  return ((w / p) * 100).toFixed(2);
};
const checkWin = (subevent, party, bidid) => {
  //console.log("checking win=>", subevent);
  var win = false;
  results.value.forEach((s) => {
    if (s.id == subevent && s.result) {
      //console.log("WINNING RESULT =>", s.result);
      if (s.result.party == party && s.result.bidid == bidid) {
        win = true;
      }
    }
  });
  return win;
};
const displayName = (id) => {
  var name = "";
  if (displaynames.value[id]) {
    name = truncateString(displaynames.value[id]);
  }
  return name;
};
function truncateString(str) {
  if (str.length > 20) {
    return str.slice(0, 17) + "...";
  }
  return str;
}
const getDisplayNames = async () => {
  unsubnames = onSnapshot(
    doc(db, "displaynames", "1scciOtNnc3SvUa7ya5e"),
    (docy) => {
      //console.log("Current data: ", doc.data());
      displaynames.value = { ...docy.data().names };
    }
  );
};
</script>
<style scoped>
.table {
  font-size: 12px;
}
</style>
