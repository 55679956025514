<template>
  <ul class="list-group">
    <li class="list-group-item bg-info text-center midtxt lesspadding">
      Declared Results
    </li>
    <li
      class="list-group-item midtxt lesspadding"
      v-for="p in sorted_prizes"
      :key="p.id"
    >
      <div class="row">
        <div class="col">
          {{ p.sno }}
          <i
            class="bi bi-trash float-end"
            v-if="todelete == p.id"
            @click="deletePrize(p.id)"
          ></i>
        </div>
        <div
          class="col border rounded"
          @click="selectToDelete(p.id, p.narration)"
        >
          <span v-if="p.user != 'SpecialPrize'">{{ displayName(p.user) }}</span>
          <span v-if="p.user == 'SpecialPrize'">{{ p.user }}</span>
        </div>
        <div class="col">
          <span v-if="todelete != p.id">{{ p.narration }}</span>

          <input
            type="text"
            class="form-control"
            v-if="todelete == p.id"
            v-model.trim="update_narration"
            @keyup.enter="updateNarration()"
          />
        </div>
      </div>
    </li>
  </ul>
</template>
<script setup>
import {
  collection,
  query,
  where,
  onSnapshot,
  doc,
  deleteDoc,
  deleteField,
  updateDoc,
} from "firebase/firestore";
import { onMounted, onBeforeUnmount, ref, computed } from "vue";
import { useStore } from "../../store/index";
import { db } from "../../firebase/config";
const store = useStore();
var unsubscribe = () => {};
var unsubnames = () => {};
const prizes = ref([]);
const displaynames = ref({});
const todelete = ref("");
const update_narration = ref("");
const selectToDelete = (user, narration) => {
  if (todelete.value != user) {
    todelete.value = user;
  } else {
    todelete.value = "";
  }
  update_narration.value = narration;
};
onMounted(() => {
  getDisplayNames();
});
onBeforeUnmount(() => {
  unsubnames();
});
const displayName = (id) => {
  var name = "";
  if (displaynames.value[id]) {
    name = truncateString(displaynames.value[id]);
  }
  return name;
};
function truncateString(str) {
  if (str.length > 20) {
    return str.slice(0, 17) + "...";
  }
  return str;
}
const getDisplayNames = async () => {
  unsubnames = onSnapshot(
    doc(db, "displaynames", "1scciOtNnc3SvUa7ya5e"),
    (docy) => {
      //console.log("Current data: ", doc.data());
      displaynames.value = { ...docy.data().names };
    }
  );
};

const sorted_prizes = computed(() => {
  const arr = [];

  for (const property in store.masterdata.prizes) {
    arr.push({ id: property, ...store.masterdata.prizes[property] });
  }
  var sorted_arr = sortByField(arr, "sno");
  return sorted_arr;
});
function sortByField(arr, field) {
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] < b[field]) {
      return 1;
    } else if (a[field] > b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
const deletePrize = async (id) => {
  //await deleteDoc(doc(db, "prizes", id));
  const cityRef = doc(db, "masterevents", store.masterevent);

  await updateDoc(cityRef, {
    ["prizes." + id]: deleteField(),
  });
};
const updateNarration = async () => {
  const washingtonRef = doc(db, "prizes", todelete.value);

  // Set the "capital" field of the city 'DC'
  await updateDoc(washingtonRef, {
    narration: update_narration.value,
  });
  todelete.value = "";
  update_narration.value = "";
};
</script>
<style scoped>
.midtxt {
  font-size: 12px;
}
.lesspadding {
  padding: 3px;
}
</style>
