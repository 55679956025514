<template>
  <div class="container zeropadding">
    <div class="row" v-if="!displaydate">
      <div class="col text-muted text-center">
        <h6>{{ search_string }}</h6>
      </div>
    </div>
    <Transition>
      <div v-if="true">
        <div class="row" v-if="displaydate">
          <div class="col-auto d-flex justify-content-around">
            <input
              type="date"
              id="dateInput"
              class="form-control padding3"
              placeholder="Date"
              v-model.trim="displaydate"
            />
          </div>
          <div class="col d-flex justify-content-end">
            <i class="bi bi-info-circle" @click="toInfo()"></i>
          </div>
        </div>
        <ul class="list-group">
          <li
            class="list-group-item overflow-hidden zeropadding mt-1 bgg"
            v-for="s in sorted_subevents"
            :key="s.id"
          >
            <SubEvent :subevent="s"></SubEvent>
            <SubeventList
              :parentevent="s.id"
              :sortby="s.subeventsort"
            ></SubeventList>
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>
<script setup>
import { useStore } from "../../store/index";
import { useSubevents } from "../../store/subevents";
import { computed, onMounted, ref, onBeforeUnmount } from "vue";
import SubeventList from "./SubeventList.vue";
import SubEvent from "./SubEvent.vue";
import { db, auth } from "../../firebase/config";
import { doc, onSnapshot } from "firebase/firestore";
import { useRouter } from "vue-router";
const router = useRouter();
const displaydate = ref("");
const store = useStore();
const se = useSubevents();
const search_string = ref("");
onMounted(() => {
  setTimeout(() => {
    fetchLatestDate();
  }, 3000);
  setSearchString();
  // const dateInput = document.getElementById("dateInput");

  // dateInput.addEventListener("change", () => {
  //   if (!dateInput.value) {
  //     //const today = new Date().toISOString().split("T")[0];
  //     //dateInput.value = today;
  //     displaydate.value = dateInput.value;
  //   }
  // });
});
const toInfo = () => {
  router.push("/info");
};
var sorted_subevents = computed(() => {
  var arr = [];
  se.subevents.forEach((s) => {
    if (s.parentevent == store.masterevent) {
      if (s.eventdate == displaydate.value) {
        arr.push(s);
      }
    }
  });
  var sorted_arr = sortByField(arr, "sno");
  return sorted_arr;
});
function sortByField(arr, field) {
  if (store.masterdata.subeventsort == "asc") {
    return arr.slice().sort((a, b) => {
      // Compare the values of the specified field
      if (a[field] > b[field]) {
        return 1;
      } else if (a[field] < b[field]) {
        return -1;
      } else {
        return 0;
      }
    });
  }
  if (store.masterdata.subeventsort == "desc") {
    return arr.slice().sort((a, b) => {
      // Compare the values of the specified field
      if (a[field] < b[field]) {
        return 1;
      } else if (a[field] > b[field]) {
        return -1;
      } else {
        return 0;
      }
    });
  }
}
const fetchLatestDate = () => {
  console.log("fetching date...");
  var arr = [];
  se.subevents.forEach((s) => {
    if (s.parentevent == store.masterevent) {
      arr.push(s);
    }
  });
  var sorted_arr = sortByField(arr, "sno");
  //console.log(sorted_arr[0].eventdate);
  displaydate.value = sorted_arr[0].eventdate;
};

const setSearchString = () => {
  const golfSayings = [
    "Lost in the rough!",
    "It’s gone to the wilderness!",
    "It’s playing hide and seek.",
    "It’s in the drink!",
    "Check the bunkers!",
    "Maybe it’s plugged.",
    "Where’s Waldo?",
    "Search party!",
    "It’s on safari!",
    "Gone fishing!",
    "Golf: The only sport where the most feared opponent is the course itself.",
    "Golf is a game where you yell 'fore,' shoot six, and write down five.",
    "Golf: The art of playing fetch with yourself.",
    "I found more greens than I lost balls today, so that's a win!",
    "Golf is like a love affair—if you don't take it seriously, it's no fun; if you do take it seriously, it breaks your heart.",
    "My favorite shots in golf are the practice swing and the conceded putt. The rest can never be mastered.",
    "If you think it's hard to meet new people, try picking up the wrong golf ball.",
    "You don't need a therapist if you own a golf club; any seven iron can do the talking.",
    "Why do golfers hate cake? Because they might get a slice!",
    "Golf: A five-mile walk punctuated with disappointments.",
    "Why did the golfer wear two shirts? In case he got a hole in one!",
    "Golf is the only sport where a lower score is better!",
    "Golfers have one thing in common: they all lie about their game.",
    "The only time my prayers are never answered is on the golf course.",
    "May the course be with you.",
    "The more I practice, the luckier I get.",
    "Golf is not just about hitting the ball; it's about managing your mistakes.",
    "In golf as in life, it's the follow-through that makes the difference.",
    "Golf is a game of endless self-reflection.",
    "A good golfer has the determination to win and the patience to wait.",
    "The most important shot in golf is the next one.",
    "Golf is deceptively simple and endlessly complicated.",
    "Success in golf depends less on strength of body than strength of mind and character.",
    "The most important shot in golf is the next one. – Ben Hogan",
    "Golf is a game of confidence and competence. But it's also a game of patience and presence. – Unknown",
    "In golf, as in life, nothing is more important than the moment you’re in. – Unknown",
    "The less effort you put into the swing, the more effortless it becomes. – Anonymous",
    "Let the game come to you, don’t force it. Every shot is a journey, not a destination. – Unknown",
    "In golf and in life, serenity comes not from controlling everything, but from embracing uncertainty. – Unknown",
    "Don’t Panic.",
    "I love deadlines. I love the whooshing noise they make as they go by.",
    "The answer to the ultimate question of life, the universe, and everything is 42.",
    "For a moment, nothing happened. Then, after a second or so, nothing continued to happen.",
    "The trouble with having an open mind, of course, is that people will insist on coming along and trying to put things in it.",
    "Real stupidity beats artificial intelligence every time.",
    "The pen is mightier than the sword if the sword is very short, and the pen is very sharp.",
    "In ancient times cats were worshiped as gods; they have not forgotten this.",
    "The Pan Galactic Gargle Blaster is like having your brain smashed out by a slice of lemon wrapped around a large gold brick.",
    "You play your game.",
  ];
  const randomIndex = Math.floor(Math.random() * golfSayings.length);
  search_string.value = golfSayings[randomIndex];
};
</script>
<style scoped>
.list-group {
  font-size: 12px;
}
.zeropadding {
  padding: 0px;
}
.padding3 {
  padding: 3px;
}
.stacked-container {
  position: relative;
}

.top-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.bottom-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.container {
  background: linear-gradient(to bottom right, #fcfcfc, #fafafa);
}
.bgg {
  background: linear-gradient(to bottom right, #fcfcfc, #fafafa);
}
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
