<template>
  <div class="row">
    <div class="col">
      <AdsBanner></AdsBanner>
    </div>
  </div>
  <div class="container">
    <div class="row overflow-hidden">
      <div class="col">
        <div class="card mt-1 mx-auto lgtxt my-container">
          <div class="card-header text-center">
            <i class="bi bi-info-circle float-start" @click="toInfo()"></i>
            Leaderboard for {{ store.mastername }}
            <i
              class="bi bi-trophy float-end glow-primary"
              v-if="show == 'prize'"
              @click="showWhat('leaderboard')"
            ></i>
            <i
              class="bi bi-award float-end glow-primary"
              v-if="show == 'leaderboard'"
              @click="showWhat('prize')"
            ></i>
          </div>
          <div class="card-body" v-if="show == 'leaderboard'">
            <table
              class="table table-bordered rounded"
              v-if="leaderboard.length >= 1"
            >
              <tr v-if="undeclaredsubevents.length">
                <td class="text-end text-danger">Result awaited...</td>
                <td class="text-center text-danger">
                  {{ undeclaredsubevents.length }}
                </td>
                <td class="text-center"></td>
                <td class="text-center"></td>
              </tr>
              <tr>
                <th class="text-start">Name</th>
                <!-- <th class="text-center">Played</th> -->
                <th class="text-center" @click="sortCol('wins')">
                  <!-- Wins <i class="bi bi-caret-down-fill text-success"></i> -->
                  Wins
                  <i class="bi bi-caret-down" v-if="sort_col != 'wins'"></i>
                  <i
                    class="bi bi-caret-down-fill text-success"
                    v-if="sort_col == 'wins'"
                  ></i>
                </th>
                <th class="text-center" @click="sortCol('sr')">
                  SR%
                  <i class="bi bi-caret-down" v-if="sort_col != 'sr'"></i>
                  <i
                    class="bi bi-caret-down-fill text-success"
                    v-if="sort_col == 'sr'"
                  ></i>
                </th>
              </tr>
              <tr v-for="(l, index) in sorted_leaderboard" :key="l.u">
                <td class="text-start" style="width: 60%">
                  {{ index + 1 }}. {{ displayName(l.u) }}
                </td>
                <!-- <td class="text-center" style="width: 20%">
                  {{ l.p }}
                </td> -->
                <td class="text-center" style="width: 20%">
                  {{ l.w }}
                </td>
                <td class="text-center" style="width: 20%">
                  <!-- below step has to be done because interger sr values will remove decimal place -->
                  {{ parseFloat(l.s).toFixed(2) }}
                </td>
              </tr>
              <tr></tr>
            </table>
            <img
              src="../../assets/calvin.jpeg"
              class="img-fluid"
              v-if="leaderboard.length < 1"
            />
          </div>
          <div class="card-body" v-if="show == 'prize'">
            <!-- <PrizeBoard></PrizeBoard> -->
            <ul class="list-group">
              <li
                class="list-group-item lesspadding my-container"
                v-for="p in sorted_prizes"
                :key="p"
              >
                {{ p.narration }}
                <span v-if="p.user != 'SpecialPrize'">
                  {{ displayName(p.user) }}
                </span>
                <!-- <i
                  class="bi bi-arrows-angle-expand float-end"
                  v-if="p.user != 'SpecialPrize'"
                ></i> -->
              </li>
            </ul>
            <img
              src="../../assets/ch.jpeg"
              class="img-fluid rounded mx-auto"
              v-if="sorted_prizes.length < 1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import AdsBanner from "../ads/AdsBanner.vue";
import { useStore } from "../../store/index";
import { useSubevents } from "../../store/subevents";
import { onMounted, onBeforeUnmount, ref, computed, watchEffect } from "vue";
import {
  doc,
  onSnapshot,
  query,
  collection,
  where,
  orderBy,
} from "firebase/firestore";
// import { PrizeBoard } from "./PrizeBoard.vue";
import { db } from "../../firebase/config";
import { useRouter } from "vue-router";
const router = useRouter();
const leaderboard = ref([]);
const prizes = ref([]);
const sort_col = ref("wins");
const show = ref("leaderboard");
const showWhat = (str) => {
  show.value = str;
};
const sortCol = (srt) => {
  sort_col.value = srt;
  if (sort_col.value == "wins") {
    leaderboard.value = leaderboard.value.sort((a, b) => b.w - a.w);
  }
  if (sort_col.value == "sr") {
    leaderboard.value = leaderboard.value.sort((a, b) => b.s - a.s);
  }
};
const se = useSubevents();
const displaynames = ref({});
const undeclaredsubevents = computed(() => {
  const arr = [];
  se.subevents.forEach((s) => {
    if (s.result == null) {
      arr.push(s.id);
    }
  });
  //populateLeaderBoard();
  return arr;
});
const store = useStore();
var unsub = () => {};
var unsub_prizes = () => {};
var unsubnames = () => {};
onBeforeUnmount(() => {
  unsub();
  unsubnames();
  unsub_prizes();
});
onMounted(() => {
  getDisplayNames();
  unsub = onSnapshot(doc(db, "leaderboards", store.masterevent), (docy) => {
    leaderboard.value = docy.data().leaderboard;
    //sort_col.value = "";
  });
  const q = query(
    collection(db, "prizes"),
    where("masterevent", "==", store.masterevent)
  );

  unsub_prizes = onSnapshot(
    doc(db, "masterevents", store.masterevent),
    (docy) => {
      var cities = [];
      for (const property in docy.data().prizes) {
        cities.push({ id: property, ...docy.data().prizes[property] });
      }
      prizes.value = cities;
    }
  );
});
const getDisplayNames = async () => {
  unsubnames = onSnapshot(
    doc(db, "displaynames", "1scciOtNnc3SvUa7ya5e"),
    (docy) => {
      //console.log("Current data: ", doc.data());
      displaynames.value = { ...docy.data().names };
    }
  );
};
const displayName = (id) => {
  var name = "";
  if (displaynames.value[id]) {
    name = truncateString(displaynames.value[id]);
  }
  return name;
};
function truncateString(str) {
  if (str.length > 25) {
    return str.slice(0, 22) + "...";
  }
  return str;
}
const sorted_prizes = computed(() => {
  const arr = [];
  prizes.value.forEach((s) => {
    arr.push(s);
  });
  var sorted_arr = sortByField(arr, "sno");
  return sorted_arr;
});
const sorted_leaderboard = computed(() => {
  var arr = [];
  leaderboard.value.forEach((s) => {
    arr.push(s);
  });
  arr = arr.map((obj) => ({
    ...obj, // Keep all other properties the same
    s: parseFloat(obj.s), // this is done because 100.00 does not come at No1 in descending sort
  }));
  var sorted_arr = [];
  if (sort_col.value == "wins") {
    sorted_arr = sortByField(arr, "w");
  }
  if (sort_col.value == "sr") {
    sorted_arr = sortByField(arr, "s");
  }

  //var sorted_arr = sortByField(arr, "w");
  return sorted_arr;
});
function sortByField(arr, field) {
  return arr.slice().sort((a, b) => {
    // Compare the values of the specified field
    if (a[field] < b[field]) {
      return 1;
    } else if (a[field] > b[field]) {
      return -1;
    } else {
      return 0;
    }
  });
}
// watchEffect(() => {
//   if (leaderboard.value.length && sort_col.value != "wins") {
//     sortCol("wins");
//   }
// });
const toInfo = () => {
  router.push("/info");
};
</script>
<style scoped>
.midtxt {
  font-size: 12px;
}
.lgtxt {
  font-size: 14px;
}
.lesspadding {
  padding: 3px;
}
.glow-primary {
  color: var(--bs-primary);
  filter: drop-shadow(0 0 10px var(--bs-primary));
}
.my-container {
  background: linear-gradient(to bottom, #fcfcfc, #fafafa);
}
</style>
